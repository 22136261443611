import AreaIcon from "./AreaIcon";

const titleByArea = {
  calida: "칼리다 교역소",
  karu: "카루숲 남쪽 교역소",
  oasis: "오아시스 교역소",
  pera: "자르딘 해변 교역소",
};

const style = {
  titleBox: "flex items-center gap-8 text-15 md:text-16 lg:text-18",
};

export default function AreaTitle({ area, icon }) {
  return (
    <div className={style.titleBox}>
      <AreaIcon icon={icon} />
      <p
        style={{
          filter:
            "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.2))",
        }}
      >
        {titleByArea[area]}
      </p>
    </div>
  );
}
