import logoImage from "../../assets/images/logo_bear.png";
import iconRecovery from "../../assets/icons/icon_recovery.png";
import { useNavigate } from "react-router-dom";

const style = {
  logoContainer: "flex justify-between items-center gap-8",
  logoImage: "h-40",
  titleText: "text-16 md:text-18 lg:text-20",
  resetButton:
    "w-18 lg:w-20 h-18 lg:h-20 cursor-pointer hover:opacity-70 active:opacity-50",
};

export default function Logo({ totalGold }) {
  const now = sessionStorage.getItem("time");
  const navigate = useNavigate();

  const handleResetClick = () => {
    navigate("/");
  };

  return (
    <div className={style.logoContainer}>
      <img className={style.logoImage} src={logoImage} alt="logo" />
      <div>
        <p className={style.titleText}>
          {`지금 물교 재료를 경매장에서 다 사면 ${totalGold.toLocaleString()} Gold`}
        </p>
        <div className="flex gap-4">
          <p className="text-14 md:text-15 lg:text-16 text-gray-700">{now}</p>
          <img
            className={style.resetButton}
            src={iconRecovery}
            alt=""
            onClick={handleResetClick}
          ></img>
        </div>
      </div>
    </div>
  );
}
