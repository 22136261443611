import { useEffect, useState } from "react";
import getPrice from "../api/getPrice";
import { needList } from "../components/area/constantDatas";
import landingBear from "../assets/images/landing.png";
import { useNavigate } from "react-router-dom";

const nowUpdating = false;
const areaList = ["pera", "calida", "karu", "oasis"];
const countByTear = [25, 15, 10, 8, 3];
const set = 1;

const style = {
  landing:
    "flex flex-col justify-center items-center m-auto w-300 text-center h-[90vh]",
};
const initialize = () => {
  if (!localStorage.getItem("initialized")) {
    localStorage.clear();
    localStorage.setItem("initialized", true);
  }

  sessionStorage.setItem("failList", JSON.stringify({}));

  if (!JSON.parse(localStorage.getItem("preset"))) {
    localStorage.setItem(
      "preset",
      JSON.stringify([
        {
          name: "성실한 상인",
          isPopup: true,
          pera1: 25,
          pera2: 15,
          pera3: 10,
          pera4: 8,
          pera5: 3,
          calida1: 25,
          calida2: 15,
          calida3: 10,
          calida4: 8,
          calida5: 3,
          karu1: 25,
          karu2: 15,
          karu3: 10,
          karu4: 8,
          karu5: 3,
          oasis1: 25,
          oasis2: 15,
          oasis3: 10,
          oasis4: 8,
          oasis5: 3,
        },
        {
          name: "체리피커",
          isPopup: true,
          pera1: 0,
          pera2: 0,
          pera3: 10,
          pera4: 8,
          pera5: 3,
          calida1: 0,
          calida2: 0,
          calida3: 10,
          calida4: 8,
          calida5: 3,
          karu1: 0,
          karu2: 0,
          karu3: 10,
          karu4: 8,
          karu5: 3,
          oasis1: 0,
          oasis2: 0,
          oasis3: 10,
          oasis4: 8,
          oasis5: 3,
        },
        {
          name: "극한의 한탕",
          isPopup: true,
          pera1: 0,
          pera2: 0,
          pera3: 7,
          pera4: 7,
          pera5: 3,
          calida1: 0,
          calida2: 0,
          calida3: 0,
          calida4: 0,
          calida5: 3,
          karu1: 0,
          karu2: 0,
          karu3: 7,
          karu4: 7,
          karu5: 3,
          oasis1: 0,
          oasis2: 0,
          oasis3: 7,
          oasis4: 7,
          oasis5: 3,
        },
      ])
    );
  }
};

export default function LandingPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [textDecoration, setTextDecoration] = useState(".");
  const navigate = useNavigate();

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hours =
    (now.getHours() + "").length >= 2 ? now.getHours() : "0" + now.getHours();
  const minutes =
    (now.getMinutes() + "").length >= 2
      ? now.getMinutes()
      : "0" + now.getMinutes();
  const seconds =
    (now.getSeconds() + "").length >= 2
      ? now.getSeconds()
      : "0" + now.getSeconds();

  sessionStorage.setItem(
    "time",
    `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds} 기준`
  );

  const needItemInfos = Object.entries(needList).flatMap(([area, items]) =>
    items.flatMap((subArray, index) =>
      subArray.map(({ name, count, max }) => ({
        name,
        count,
        area,
        tear: index + 1,
        max,
      }))
    )
  );

  useEffect(() => {
    const loadingText = setInterval(() => {
      setTextDecoration((prev) => {
        if (prev.length < 3) {
          return prev + ".";
        } else {
          return ".";
        }
      });
    }, 500);

    if (nowUpdating) return;

    initialize();

    const fetchData = async () => {
      await Promise.all(
        needItemInfos.map(
          (needItemInfo, index) =>
            new Promise((resolve) => {
              const areaItem = needItemInfo.area + needItemInfo.tear;
              sessionStorage.setItem(areaItem, 0);

              setTimeout(async () => {
                const datas = await getPrice(
                  needItemInfo.name,
                  needItemInfo.max
                );
                const areaGold =
                  parseInt(sessionStorage.getItem(areaItem)) || 0;

                const needCount =
                  needItemInfo.count * countByTear[needItemInfo.tear - 1] * set;
                let accPrice = 0;
                let feeledCount = 0;

                datas.forEach((data) => {
                  const diff = needCount - feeledCount;
                  if (diff <= 0) return;

                  const buyCount = Math.min(data.item_count, diff);
                  const price = data.auction_price_per_unit;
                  const amount = buyCount * price;
                  accPrice += amount;
                  feeledCount += buyCount;
                });

                if (needCount > feeledCount) {
                  const failList = JSON.parse(
                    sessionStorage.getItem("failList")
                  );
                  failList[needItemInfo.name] = feeledCount;
                  sessionStorage.setItem("failList", JSON.stringify(failList));
                }

                const averagePrice =
                  parseInt(accPrice / Math.min(needCount, feeledCount)) || 0;
                sessionStorage.setItem(
                  areaItem,
                  areaGold +
                    (accPrice * needCount) /
                      feeledCount /
                      countByTear[needItemInfo.tear - 1]
                );
                sessionStorage.setItem(needItemInfo.name, averagePrice);
                resolve();
              }, index * 80);
            })
        )
      );

      setIsLoading(false);
      setTimeout(() => {
        clearInterval(loadingText);
      }, 600);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isLoading) return;

    const getTotalGold = (area) => {
      const totalGold = parseInt(sessionStorage.getItem("total")) || 0;

      const totalAreaGold = countByTear.reduce((acc, value, index) => {
        const price =
          parseInt(sessionStorage.getItem(`${area}${index + 1}`)) || 0;

        return acc + price * value;
      }, 0);

      sessionStorage.setItem("total", totalGold + totalAreaGold);
      sessionStorage.setItem(`${area}-gold`, totalAreaGold);
      sessionStorage.setItem("ranks", 0);
      sessionStorage.setItem("warrantys", 0);
    };

    areaList.forEach(getTotalGold);

    setTimeout(() => {
      navigate("/main");
    }, 1000);
  }, [isLoading]);

  return (
    <div className={style.landing}>
      <img className="absolute" src={landingBear}></img>
      <p className=" relative top-96 z-10 text-white">
        필요한 데이터를 불러오고 있어요!
      </p>
      <p className="relative top-96 z-10 text-white">
        잠시 후 이동합니다{textDecoration}
      </p>
    </div>
  );
}
