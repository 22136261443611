import { useEffect, useRef, useState } from "react";
import AreaIcon from "./AreaIcon";
import { itemList } from "./constantDatas";
import NeedItems from "./NeedItems";
import iconRecovery from "../../assets/icons/icon_recovery.png";

const style = {
  itemMatchingBox: "md:flex",
  itemBox:
    "pl-6 md:pl-8 lg:pl-12 flex items-center md:text-12 lg:text-15 md:max-w-288 lg:min-w-380 border-b-1 border-dashed md:border-solid border-gray-300",
  itemBoxHeight: [
    "h-64 md:h-86",
    "h-64 md:h-86",
    "h-64 md:h-86",
    "h-64 md:h-124",
    "h-64 md:h-124",
  ],
  userCountInput:
    "w-24 lg:w-32 md:text-13 lg:text-15 text-gray-700 bg-gray-100 rounded-5 text-center leading-[24px] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
  textFlex:
    "text-13 lg:text-15 flex justify-between items-center w-full md:w-250 p-6 lg:p-10 lg:w-320 h-24 md:border-r-1 md:border-solid md:border-gray-300",
  itemAndCountBox: "flex gap-4 items-center",
  warning: "md:text-10 lg:text-12 text-red-500",
  iconRecovery:
    " w-15 h-15 lg:w-18 lg:h-18 cursor-pointer hover:opacity-80 active:opacity-60",
  offToggle: "text-gray-500",
};

export default function AreaItem({
  area,
  icon,
  tear,
  updateGold,
  updateSlotAndWeight,
  preset,
  breakPreset,
}) {
  const item = itemList[area][tear - 1];
  const gold = parseInt(sessionStorage.getItem(`${area}${tear}`)) || 0;

  const [toggle, setToggle] = useState("on");
  const [userCount, setUserCount] = useState(item.canBuy);
  const [isWarning, setIsWarning] = useState(false);
  const prevCountRef = useRef("");

  const handleIconClick = () => {
    const goldChange = userCount * gold;
    const slotChange = Math.ceil(userCount / item.slot);
    const weightChange = parseInt(userCount * item.weight);
    let itemCounts = JSON.parse(sessionStorage.getItem("itemCounts") || "{}");

    if (toggle === "on") {
      setToggle("off");
      updateGold(-goldChange);
      updateSlotAndWeight(-slotChange, -weightChange);
      breakPreset();
      itemCounts[area + tear] = parseInt(0);
      sessionStorage.setItem("itemCounts", JSON.stringify(itemCounts));
    } else {
      setToggle("on");
      updateGold(goldChange);
      updateSlotAndWeight(slotChange, weightChange);
      breakPreset();
      itemCounts[area + tear] = parseInt(userCount);
      sessionStorage.setItem("itemCounts", JSON.stringify(itemCounts));
    }
  };

  const handleUserCountChange = (e) => {
    const nextValue = e.target.value;
    setUserCount(nextValue);
    breakPreset();
  };

  const handleUserCountBlur = (e) => {
    const nextValue = e.target.value;
    const isNotValidValue = isNaN(nextValue);
    setIsWarning(true);

    if (isNotValidValue) {
      setUserCount(prevCountRef.current);
      return;
    }

    if (nextValue === "") {
      setUserCount(0);
      return;
    }

    if (nextValue < 0) {
      setUserCount(0);
      return;
    }

    if (nextValue > item.canBuy) {
      setUserCount(item.canBuy);
      return;
    }

    setIsWarning(false);
  };

  const handleRecoveryClick = () => {
    if (toggle === "off") {
      handleIconClick();
    }

    setUserCount(item.canBuy);
    setIsWarning(false);
  };

  useEffect(() => {
    const goldChangePlus = gold * userCount;
    const goldChangeMinus = gold * prevCountRef.current;
    const goldChange = goldChangePlus - goldChangeMinus;

    const slotChangePlus = Math.ceil(userCount / item.slot);
    const slotChangeMinus = Math.ceil(prevCountRef.current / item.slot);
    const slotChange = slotChangePlus - slotChangeMinus;

    const weightChangePlus = item.weight * userCount;
    const weightChangeMinus = item.weight * prevCountRef.current;
    const weightChange = weightChangePlus - weightChangeMinus;

    if (isNaN(goldChange)) {
      return;
    }

    updateGold(goldChange);
    updateSlotAndWeight(slotChange, weightChange);
    prevCountRef.current = userCount;

    let itemCounts = JSON.parse(sessionStorage.getItem("itemCounts") || "{}");
    itemCounts[area + tear] = parseInt(userCount);

    sessionStorage.setItem("itemCounts", JSON.stringify(itemCounts));
  }, [userCount]);

  useEffect(() => {
    if (preset === -1) return;

    const presetList = JSON.parse(localStorage.getItem("preset"));
    const itemCountInPreset = presetList.find((set) => set.name === preset);
    const itemCount = itemCountInPreset[area + tear];

    if (toggle === "off") {
      handleIconClick();
    }
    setUserCount(itemCount);

    if (itemCount === 0) {
      setToggle("off");
    } else {
      setToggle("on");
    }
  }, [preset]);

  return (
    <div className={style.itemMatchingBox}>
      <div className={`${style.itemBox} ${style.itemBoxHeight[tear - 1]}`}>
        <AreaIcon
          size="medium"
          icon={icon[toggle][tear - 1]}
          type="button"
          onIconClick={handleIconClick}
          item={item}
          isPopup={true}
          userCount={userCount}
        />
        <div className={`${style.textFlex} ${style[`${toggle}Toggle`]}`}>
          <div>
            <div className={style.itemAndCountBox}>
              <p>{item.name} * </p>
              <input
                className={style.userCountInput}
                value={userCount}
                onChange={handleUserCountChange}
                onBlur={handleUserCountBlur}
                disabled={toggle === "off"}
              />
              <img
                className={style.iconRecovery}
                src={iconRecovery}
                onClick={handleRecoveryClick}
              ></img>
            </div>
            {isWarning && (
              <p className={style.warning}>
                * 0부터 {item.canBuy}까지의 숫자만 입력할 수 있어요!
              </p>
            )}
          </div>
          <p>{parseInt(gold * userCount).toLocaleString()} Gold</p>
        </div>
      </div>
      <NeedItems
        area={area}
        tear={tear}
        toggle={toggle}
        userCount={userCount}
      />
    </div>
  );
}
