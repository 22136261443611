import { useEffect } from "react";
import { useState } from "react";
import Dropdown from "../components/Dropdown";
import Header from "../components/header/Header";
import AddPresetPopup from "../components/preset/AddPresetPopup";
import PresetButton from "../components/preset/PresetButton";
import DetailPage from "./DetailPage";

const style = {
  headerSize: "h-80",
  infosGap: "md:py-4 lg:py-12 flex flex-col items-center gap-24 lg:gap-32 ",
  graphTitle:
    "hidden md:flex justify-between items-center h-48 m-auto pt-12 md:pt-24 lg:pt-32 md:w-640 lg:w-800",
  graphText: "text-right md:text-14 lg:text-16",
  graphSize: ["md:w-270 lg:w-360", "md:w-370 lg:w-400"],
  topOptionContainer: "flex flex-col gap-24 lg:gap-32",
  optionContainer:
    "overflow-hidden transition-all duration-500 flex flex-col gap-20",
  optionClosed: "max-h-0",
  optionOpened: "max-h-1000",
  topOptionBox:
    "m-auto flex flex-col justify-center gap-4 md:gap-6 lg:gap-8 items-center",
  topOptionList: "text-12 md:text-14 lg:text-16 flex gap-12",
  ducatRateInput:
    "w-32 bg-gray-300 rounded-5 text-center leading-[24px] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
  isError: "text-red-500",
  presetButtonBox:
    "relative flex gap-4 md:gap-6 lg:gap-8 top-[-4px] md:top-[-3px]",
  presetButton:
    "text-12 md:text-14 lg:text-16 bg-blue-100 hover:bg-blue-200 active:bg-blue-300 px-6 md:px-10 lg:px-12 py-2 rounded-md",
};

sessionStorage.setItem("ranks", 0);
sessionStorage.setItem("warrantys", 0);

export default function MainPage() {
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [isDurationEnd, setIsDutationEnd] = useState(true);
  const [totalGold, setTotalGold] = useState(0);
  const [bonusRate, setBonusRate] = useState(0);
  const [selectedRank, setSelectedRank] = useState(0);
  const [selectedWarranty, setSelectedWarranty] = useState(0);
  const [slotAndWeight, setSlotAndWeight] = useState([4, 400]);
  const [selectedGrandMaster, setSelectedGrandMaster] = useState([0, 0]);
  const [selectedRide, setSelectedRide] = useState([4, 400]);
  const [selectedPartner, setSelectedPartner] = useState([0, 0]);
  const [isSelectedError, setIsSelectedError] = useState(false);
  const [preset, setPreset] = useState(-1);
  const [presetList, setPresetList] = useState(
    JSON.parse(localStorage.getItem("preset"))
  );
  const [addPresetModalOpen, setAddPresetModalOpen] = useState(false);

  const handleOptionOpenClick = () => {
    setIsDutationEnd(false);
    setIsOptionOpen((prev) => !prev);

    setTimeout(() => {
      setIsDutationEnd(true);
    }, 500);
  };

  const handleUpdateGold = (gold) => {
    setTotalGold((prev) => prev + gold);
  };

  const handleUpdateRank = (rank) => {
    setSelectedRank(rank.value);
  };

  const handleUpdateWarranty = (warranty) => {
    setSelectedWarranty(warranty.value);
  };

  const handleUpdateGrandMaster = (grandMaster) => {
    setSelectedGrandMaster(() => grandMaster.value);
  };

  const handleUpdateRide = (ride) => {
    setSelectedRide(() => ride.value);
  };

  const handleUpdatePartner = (partner) => {
    setSelectedPartner(() => partner.value);
  };

  const handlePresetClick = (e) => {
    const nextValue = e.target.innerText;
    setPreset(nextValue);
  };

  const handleBreakPreset = () => {
    setPreset(-1);
  };

  const handleAddPresetModalOpenClick = () => {
    setAddPresetModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleAddPresetModalCloseClick = () => {
    setAddPresetModalOpen(false);
    document.body.style.overflow = "";
  };

  const updatePreset = () => {
    setPresetList(JSON.parse(localStorage.getItem("preset")));
  };

  useEffect(() => {
    setBonusRate(selectedRank + selectedWarranty);

    const updatedSlotAndWeight = [
      selectedGrandMaster[0] + selectedRide[0] + selectedPartner[0],
      selectedGrandMaster[1] + selectedRide[1] + selectedPartner[1],
    ];
    setSlotAndWeight(updatedSlotAndWeight);

    const hasError =
      selectedPartner[0] === 2 &&
      selectedRide[0] !== 8 &&
      selectedRide[1] !== 900;

    if (hasError) {
      setIsSelectedError(hasError);
      setSlotAndWeight([0, 0]);
      sessionStorage.setItem(
        "slotAndWeight",
        JSON.stringify(hasError ? [0, 0] : updatedSlotAndWeight)
      );
    }
  }, [
    bonusRate,
    selectedRank,
    selectedWarranty,
    selectedGrandMaster,
    selectedRide,
    selectedPartner,
  ]);

  return (
    <>
      <div className={style.headerSize}>
        <Header totalGold={totalGold} />
      </div>
      <div className="flex justify-center relative top-[-23px] md:top-[-25px] lg:top-[-28px]">
        <button
          className="border-1 border-solid border-gray-400 rounded-12 pt-14 md:pt-16 lg:pt-20 px-12 text-12 md:text-14"
          onClick={handleOptionOpenClick}
        >
          Option
        </button>
      </div>
      <div className={style.topOptionContainer}>
        <div
          className={`${style.optionContainer} ${
            isOptionOpen ? style.optionOpened : style.optionClosed
          } ${
            isDurationEnd && isOptionOpen
              ? "overflow-visible"
              : "overflow-hidden"
          }`}
        >
          <div className={style.topOptionBox}>
            <div className={style.topOptionList}>
              <p>Q. 교역 마스터리가 몇랭크이신가요?</p>
              <Dropdown listType="ranks" updateRate={handleUpdateRank} />
            </div>
            <div className={style.topOptionList}>
              <p>Q. 보증서를 사용할까요?</p>
              <Dropdown
                listType="warrantys"
                updateRate={handleUpdateWarranty}
              />
            </div>
            <div className={style.topOptionList}>
              <p>* 총 {bonusRate}%의 보너스를 받았어요!</p>
            </div>
          </div>
          <div className={style.topOptionBox}>
            <div className={style.topOptionList}>
              <p>Q. 그랜드마스터 상인이신가요?</p>
              <Dropdown
                listType="grandMasters"
                updateRate={handleUpdateGrandMaster}
              />
            </div>
            <div className={style.topOptionList}>
              <p>Q. 어떤 운송수단을 사용할까요?</p>
              <Dropdown listType="rides" updateRate={handleUpdateRide} />
            </div>
            <div className={style.topOptionList}>
              <p>Q. 여행을 함께할 파트너가 있나요?</p>
              <Dropdown listType="partners" updateRate={handleUpdatePartner} />
            </div>
            <div className={style.topOptionList}>
              {isSelectedError ? (
                <p className={style.isError}>
                  * 알파카는 마차와 함께 사용해야 해요!
                </p>
              ) : (
                <p>
                  * {isSelectedError}1회 운송할 때 {slotAndWeight[0]}칸에{" "}
                  {slotAndWeight[1]}만큼 실을 수 있어요!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={style.topOptionBox}>
          <div className={style.presetButtonBox}>
            <p className="text-14 md:text-16 lg:text-18 ">프리셋 리스트</p>
            <button
              className={style.presetButton}
              onClick={handleAddPresetModalOpenClick}
            >
              커스텀 프리셋 추가
            </button>
          </div>
          <div className="flex gap-6 pb-4 md:pb-0">
            <div className="flex gap-4 w-320 md:w-600 lg:w-720 flex-wrap items-center justify-center">
              {presetList.map((preset) => (
                <PresetButton
                  key={preset.name}
                  preset={preset}
                  onClick={handlePresetClick}
                  isPopup={preset.isPopup}
                  updatePreset={updatePreset}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <DetailPage
        updateGold={handleUpdateGold}
        preset={preset}
        breakPreset={handleBreakPreset}
        maxSlotAndWeight={slotAndWeight}
      />
      {addPresetModalOpen && (
        <AddPresetPopup
          addPreset={updatePreset}
          closePopup={handleAddPresetModalCloseClick}
        />
      )}
    </>
  );
}
