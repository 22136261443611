import AreaIcon from "./AreaIcon";
import { needList } from "./constantDatas";

const style = {
  needItemBox:
    "flex flex-col justify-center md:w-370 lg:w-420 items-center border-b-1 border-solid border-gray-300 md:pr-6 lg:pr-10",
  nameBox: "md:w-180 lg:w-200 text-12 lg:text-14 lg:pl-4",
  nameText: "h-16",
  warningText: "text-red-500",
  needItem:
    "flex items-center py-4 md:w-350 lg:w-410 justify-between px-6 md:px-4 w-full",
  needItemNameBox: "flex items-center gap-4",
  offToggle: "text-gray-500",
  needItemGold: "md:w-150 lg:w-180 text-right text-12 lg:text-14",
  itemDivBorder: "md:border-b-1 md:border-solid md:border-gray-300",
};

export default function NeedItems({ area, tear, toggle, userCount }) {
  const needItems = needList[area][tear - 1];
  const failedItem = JSON.parse(sessionStorage.getItem("failList"));

  return (
    <div className={style.needItemBox}>
      {needItems.map((needItem, index) => {
        const gold = parseInt(sessionStorage.getItem(needItem.name)) || 0;
        return (
          <div
            className={`${style.needItem} ${
              index !== needItems.length - 1 && style.itemDivBorder
            } ${style[`${toggle}Toggle`]}`}
            key={needItem.name}
          >
            <div className={style.needItemNameBox}>
              <AreaIcon
                size="small"
                icon={needItems[index].icon}
                toggle={toggle}
              />
              <div className={style.nameBox}>
                <p className={style.nameText}>
                  {needItem.name} * {needItem.count * userCount}
                </p>
                {failedItem[needItem.name] && (
                  <p className={`${style.nameText} ${style.warningText}`}>
                    * 수량 부족! 현재 매물: {failedItem[needItem.name]}개
                  </p>
                )}
              </div>
            </div>
            <p className={style.needItemGold}>{`${(
              gold *
              needItem.count *
              userCount
            ).toLocaleString()} Gold (${gold.toLocaleString()} / 1ea)`}</p>
          </div>
        );
      })}
    </div>
  );
}
