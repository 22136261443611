import AreaItem from "./AreaItem";
import AreaTitle from "./AreaTitle";
import { iconByArea } from "./constantDatas";
import { useState } from "react";
import { useEffect } from "react";

const style = {
  areaInfoBox: "flex flex-col text-white shadow-lg shadow-slate-600",
  titleTextContainer:
    "flex justify-between items-center w-360 md:w-660 lg:w-800 m-auto text-14 md:text-14 lg:text-16",
  titleTextBox: "flex items-center",
  titleTextRight: "md:w-181 lg:w-228",
  titleTextMiddle: "hidden md:flex md:w-290 lg:w-320 justify-start gap-8",
  itemList: "relative flex flex-col",
  goldPadding: "pr-8",
  overCarry: "text-red-500",
};

const areaColor = {
  pera: { title: "bg-[#382119]", items: ["bg-[#83675c]", "bg-[#58423a]"] },
  calida: {
    title: "bg-[#091d47]",
    items: ["bg-[#1f3869]", "bg-[#294988]"],
  },
  karu: { title: "bg-[#093618]", items: ["bg-[#1b552e]", "bg-[#32864e]"] },
  oasis: { title: "bg-[#4adad2]", items: ["bg-[#24948e]", "bg-[#00706b]"] },
};

export default function AreaInfo({
  area,
  updateGold,
  preset,
  breakPreset,
  maxSlotAndWeight,
}) {
  const [totalAreaGold, setTotalAreaGold] = useState(0);
  const [slotAndWeight, setSlotAndWeight] = useState([0, 0]);
  const [isOverCarry, setIsOverCarry] = useState([false, false]);

  const icon = iconByArea[area];

  const updateActiveItems = (gold) => {
    setTotalAreaGold((prev) => prev + gold);
    updateGold(gold);
  };

  const updateSlotAndWeight = (slot, weight) => {
    setSlotAndWeight((prev) => [prev[0] + slot, prev[1] + weight]);
  };

  useEffect(() => {
    if (slotAndWeight[0] > maxSlotAndWeight[0]) {
      setIsOverCarry((prev) => [true, prev[1]]);
    } else {
      setIsOverCarry((prev) => [false, prev[1]]);
    }

    if (slotAndWeight[1] > maxSlotAndWeight[1]) {
      setIsOverCarry((prev) => [prev[0], true]);
    } else {
      setIsOverCarry((prev) => [prev[0], false]);
    }
  }, [slotAndWeight, maxSlotAndWeight]);

  return (
    <div className={style.areaInfoBox}>
      <div className={`${style.titleTextContainer} ${areaColor[area].title}`}>
        <div className={`${style.titleTextBox} ${style.titleTextRight}`}>
          <AreaTitle area={area} icon={icon.area} />
        </div>
        <div className={`${style.titleTextBox} ${style.titleTextMiddle}`}>
          <p
            className={isOverCarry[0] ? style.overCarry : ""}
            style={{
              filter:
                "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.2))",
            }}
          >
            칸: {slotAndWeight[0]}
          </p>
          <p>/</p>
          <p
            className={isOverCarry[1] ? style.overCarry : ""}
            style={{
              filter:
                "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.2))",
            }}
          >
            무게: {slotAndWeight[1]}
          </p>
        </div>
        <div className={style.titleTextBox}>
          <p
            className={style.goldPadding}
            style={{
              filter:
                "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.2))",
            }}
          >
            합계: {totalAreaGold.toLocaleString()} Gold
          </p>
        </div>
      </div>
      <div className={style.itemList}>
        {[1, 2, 3, 4, 5].map((tear) => (
          <div className={areaColor[area].items[tear % 2]} key={area + tear}>
            <AreaItem
              area={area}
              icon={icon}
              tear={tear}
              updateGold={updateActiveItems}
              updateSlotAndWeight={updateSlotAndWeight}
              preset={preset}
              breakPreset={breakPreset}
              key={area + tear}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
