import { useState } from "react";

const style = {
  addPresetPopupBackground:
    "fixed flex items-center top-0 left-0 bg-black bg-opacity-70 w-full h-full z-50",
  container:
    "flex flex-col bg-white w-360 lg:w-480 rounded-xl m-auto p-24 lg:p-36 justify-between",
  flexColBox: "flex flex-col",
  labelText: "text-16 lg:text-18",
  inputBox: "bg-gray-300 px-12 py-6 text-14 lg:text-16 rounded-md",
  buttonAreaGap: "gap-2 lg:gap-8 mt-12 lg:mt-16",
  buttonAreaText: "text-14 lg:text-16",
  buttonBox: "flex gap-8",
  buttonPrimary:
    "bg-blue-300 hover:bg-blue-400 active:bg-blue-500 grow px-12 py-4 lg:py-6 rounded-md",
  buttonSecondary:
    "bg-gray-300 hover:bg-gray-400 active:bg-gray-500 grow px-12 py-4 lg:py-6 rounded-md",
  errorText: "mt-2 text-red-500 text-12 lg:text-14",
};

const errorMessage = ["", "* 중복된 이름입니다!", "* 이름을 입력해 주세요!"];

export default function AddPresetPopup({ addPreset, closePopup }) {
  const [presetName, setPresetName] = useState("");
  const [errorCode, setErrorCode] = useState(false);

  const handlePresetNameChange = (e) => {
    const nextValue = e.target.value;
    setPresetName(nextValue);
  };
  const handleAddPresetClick = () => {
    let currentPresetList = JSON.parse(localStorage.getItem("preset")) || [];

    if (currentPresetList.some((preset) => preset.name === presetName)) {
      setErrorCode(1);
      return;
    }
    console.log(presetName);
    if (!presetName) {
      setErrorCode(2);
      return;
    }

    setErrorCode(false);

    const currentItemCounts =
      JSON.parse(sessionStorage.getItem("itemCounts")) || {};
    const newPreset = {
      name: presetName,
      isPopup: false,
      ...currentItemCounts,
    };

    currentPresetList.push(newPreset);
    localStorage.setItem("preset", JSON.stringify(currentPresetList));

    closePopup();
    addPreset();
  };

  return (
    <div className={style.addPresetPopupBackground}>
      <div className={style.container}>
        <div className={style.flexColBox}>
          <label className={style.labelText} htmlFor="presetName">
            프리셋의 이름을 입력해 주세요!
          </label>
          <input
            className={style.inputBox}
            type="text"
            value={presetName}
            onChange={handlePresetNameChange}
          />
          {errorCode && (
            <p className={style.errorText}>{errorMessage[errorCode]}</p>
          )}
        </div>
        <div className={`${style.flexColBox} ${style.buttonAreaGap}`}>
          <p className={style.buttonAreaText}>
            * 프리셋은 현재 적용중인 수량으로 추가됩니다!
          </p>
          <div className={style.buttonBox}>
            <button
              className={style.buttonPrimary}
              onClick={handleAddPresetClick}
            >
              추가
            </button>
            <button className={style.buttonSecondary} onClick={closePopup}>
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
