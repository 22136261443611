const API_KEY = process.env.REACT_APP_API_KEY;
const urlString =
  "https://open.api.nexon.com/mabinogi/v1/auction/list?item_name=";

export default async function getPrice(itemName, maxCount) {
  const url = urlString + itemName;

  try {
    const response = await fetch(url, {
      headers: {
        "x-nxopen-api-key": API_KEY,
      },
    });

    const data = await response.json();

    const items = data.auction_item;

    // const sortedItems = items
    //   .filter((item) => parseInt(item.item_count) >= maxCount)
    //   .sort((a, b) => a.auction_price_per_unit - b.auction_price_per_unit);

    const sortedItems = items.sort(
      (a, b) => a.auction_price_per_unit - b.auction_price_per_unit
    );

    return sortedItems;
  } catch (error) {
    return [];
  }
}
