import { useState } from "react";
import AreaItemPopup from "./AreaItemPopup";

const style = {
  large: "w-36 md:w-40 lg:w-48 h-36 md:h-40 lg:h-48 ",
  medium: "w-32 lg:w-40 w-32 lg:h-40 ",
  small: "w-24 lg:w-32 h-24 lg:h-32 ",
  isButton:
    "hover:shadow hover:shadow-red-200 hover:opacity-80 active:opacity-60",
  onToggle: "",
  offToggle: "grayscale",
};

export default function AreaIcon({
  size = "large",
  icon,
  type = "icon",
  onIconClick,
  toggle,
  item,
  isPopup = false,
  userCount,
}) {
  const [popupOpen, setPopupOpen] = useState(false);
  const pointer = onIconClick && "cursor-pointer";

  const handleMouseEnter = () => {
    setPopupOpen(true);
  };
  const handleMouseLeave = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <img
        className={`${style[size]} ${type === "button" && style.isButton} ${
          style[`${toggle}Toggle`]
        } ${pointer}`}
        src={icon}
        onClick={onIconClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      ></img>
      {isPopup && popupOpen && (
        <AreaItemPopup item={item} userCount={userCount} />
      )}
    </>
  );
}
