import React, { useEffect, useRef, useState } from "react";

const options = {
  ranks: [
    { key: "연습 (0%)", value: 0 },
    { key: "1 (15%)", value: 15 },
    { key: "2 (14%)", value: 14 },
    { key: "3 (13%)", value: 13 },
    { key: "4 (12%)", value: 12 },
    { key: "5 (11%)", value: 11 },
    { key: "6 (10%)", value: 10 },
    { key: "7 (9%)", value: 9 },
    { key: "8 (8%)", value: 8 },
    { key: "9 (7%)", value: 7 },
    { key: "A (6%)", value: 6 },
    { key: "B (5%)", value: 5 },
    { key: "C (4%)", value: 4 },
    { key: "D (3%)", value: 3 },
    { key: "E (2%)", value: 2 },
    { key: "F (1%)", value: 1 },
  ],
  warrantys: [
    { key: "아니요", value: 0 },
    { key: "임프의 특별한 보증서 (150%)", value: 150 },
    { key: "이루샤의 특별한 보증서 (75%)", value: 75 },
    { key: "임프의 고급 보증서 (75%)", value: 75 },
    { key: "고블린의 고급 보증서 (60%)", value: 60 },
    { key: "오거의 고급 보증서 (45%)", value: 45 },
    { key: "임프의 보증서 (25%)", value: 25 },
    { key: "고블린의 보증서 (20%)", value: 20 },
    { key: "오거의 보증서 (15%)", value: 15 },
  ],
  grandMasters: [
    { key: "아니요", value: [0, 0] },
    { key: "네 (칸: 1, 무게: 100)", value: [1, 100] },
  ],
  rides: [
    { key: "등짐 (칸: 4, 무게: 400)", value: [4, 400] },
    { key: "손수레 (칸: 6, 무게: 800)", value: [6, 800] },
    { key: "마차 (칸: 8, 무게: 900)", value: [8, 900] },
    { key: "코끼리 (칸: 7, 무게: 1700)", value: [7, 1700] },
    { key: "개썰매 (칸: 11, 무게: 700)", value: [11, 700] },
    { key: "낙타 (칸: 7, 무게: 1400)", value: [7, 1400] },
    { key: "부유선 (칸: 8, 무게: 1200)", value: [8, 1200] },
  ],
  partners: [
    { key: "아니요", value: [0, 0] },
    { key: "교역파트너 (칸: 1, 무게: 100)", value: [1, 100] },
    { key: "윌리엄 (칸: 1, 무게: 200)", value: [1, 200] },
    { key: "[마차]알파카 (칸: 2, 무게: 200)", value: [2, 200] },
  ],
};

const style = {
  dropdownContainer: "relative inline-block text-left",
  dropdownButton:
    "bg-gray-300 px-6 md:px-10 lg:px-12 py-2 rounded-md hover:bg-gray-400 active:bg-gray-500 focus:outline-none",
  dropdownList:
    "z-30 max-y-100 absolute right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-lg",
  dropdownItem:
    "block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-gray-200 cursor-pointer",
  ranksWidth: "min-w-54 md:min-w-64 lg:min-w-72",
  warrantysWidth: "min-w-144 md:min-w-168 lg:min-w-190",
  grandMastersWidth: "min-w-100 md:min-w-116 lg:min-w-132",
  ridesWidth: "min-w-128 md:min-w-148 lg:min-w-168",
  partnersWidth: "min-w-160 md:min-w-180 lg:min-w-200",
};

export default function Dropdown({ listType, updateRate }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options[listType][0].key
  );
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleItemClick = (selectedOption) => {
    sessionStorage.setItem(listType, JSON.stringify(selectedOption.value));
    localStorage.setItem(
      `selected${listType}`,
      JSON.stringify({ key: selectedOption.key, value: selectedOption.value })
    );
    setSelectedOption(selectedOption.key);
    updateRate(selectedOption);
    setIsOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  useEffect(() => {
    const savedItem = JSON.parse(localStorage.getItem(`selected${listType}`));
    if (!savedItem) return;

    handleItemClick(savedItem);
  }, []);

  return (
    <div className={style.dropdownContainer} ref={dropdownRef}>
      <button onClick={toggleDropdown} className={style.dropdownButton}>
        {selectedOption}
      </button>
      {isOpen && (
        <div className={`${style.dropdownList} ${style[`${listType}Width`]}`}>
          {options[listType].map((item) => (
            <p
              key={item.key}
              className={style.dropdownItem}
              onClick={() => handleItemClick(item)}
            >
              {item.key}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}
