const style = {
  popupBox:
    "z-40 px-16 pt-12 pb-4 absolute top-24 left-[-50px] text-gray-800 text-10 md:text-12 lg:text-14 flex flex-col justify-center left-57 w-220 md:w-270 lg:w-300 bg-yellow-50 rounded-16 shadow-md shadow-red-200 border-2 border-solid border-pink-100",
  divLine: "pt-6 border-b-2 border-solid border-pink-200",
  alertMessage: "flex items-center justify-center pt-6 text-center",
};

const presetPopupComposition = {
  text: {
    "성실한 상인": ["한 교역소의 모든 물품을 구매해요!"],
    체리피커: ["두 교역소의 3~5티어 물품만 모두 구매해요!"],
    "극한의 한탕": [
      "모든 교역소의 3~5티어 물품을 한 칸씩 구매해요!",
      "단, 칼리다는 5티어 물품만 구매해요.",
    ],
  },
  transitCount: {
    "성실한 상인": 4,
    체리피커: 2,
    "극한의 한탕": 1,
  },
};

export default function PresetPopup({ preset }) {
  return (
    <div className={style.popupBox}>
      {presetPopupComposition.text[preset.name].map((text, index) => (
        <p key={preset.name + index}>{text}</p>
      ))}

      <div className={style.divLine} />

      <div className={style.alertMessage}>
        <p>운송 횟수: {presetPopupComposition.transitCount[preset.name]}회</p>
      </div>
    </div>
  );
}
