import clothes1 from "../../assets/icons/item_clothes01.png";
import clothes2 from "../../assets/icons/item_clothes02.png";
import clothes3 from "../../assets/icons/item_clothes03.png";
import clothes4 from "../../assets/icons/item_clothes04.png";
import clothes5 from "../../assets/icons/item_clothes05.png";
import clothes6 from "../../assets/icons/item_clothes06.png";
import clothes7 from "../../assets/icons/item_clothes07.png";
import cook1 from "../../assets/icons/item_cook01.png";
import cook2 from "../../assets/icons/item_cook02.png";
import cook3 from "../../assets/icons/item_cook03.png";
import hand1 from "../../assets/icons/item_hand01.png";
import hand2 from "../../assets/icons/item_hand02.png";
import hand3 from "../../assets/icons/item_hand03.png";
import hand4 from "../../assets/icons/item_hand04.png";
import hand5 from "../../assets/icons/item_hand05.png";
import heulwen1 from "../../assets/icons/item_heulwen01.png";
import heulwen2 from "../../assets/icons/item_heulwen02.png";
import heulwen3 from "../../assets/icons/item_heulwen03.png";
import heulwen4 from "../../assets/icons/item_heulwen04.png";
import heulwen5 from "../../assets/icons/item_heulwen05.png";
import magic1 from "../../assets/icons/item_magic01.png";
import magic2 from "../../assets/icons/item_magic02.png";
import magic3 from "../../assets/icons/item_magic03.png";
import magic4 from "../../assets/icons/item_magic04.png";
import magic5 from "../../assets/icons/item_magic05.png";
import magic6 from "../../assets/icons/item_magic06.png";
import magipet1 from "../../assets/icons/item_magipet01.png";
import magipet2 from "../../assets/icons/item_magipet02.png";
import magipet3 from "../../assets/icons/item_magipet03.png";
import magipet4 from "../../assets/icons/item_magipet04.png";
import magipet5 from "../../assets/icons/item_magipet05.png";
import mine1 from "../../assets/icons/item_mine01.png";
import mine2 from "../../assets/icons/item_mine02.png";
import mine3 from "../../assets/icons/item_mine03.png";
import mine4 from "../../assets/icons/item_mine04.png";
import mine5 from "../../assets/icons/item_mine05.png";
import potion1 from "../../assets/icons/item_potion01.png";
import potion2 from "../../assets/icons/item_potion02.png";
import potion3 from "../../assets/icons/item_potion03.png";
import potion4 from "../../assets/icons/item_potion04.png";
import potion5 from "../../assets/icons/item_potion05.png";
import wood1 from "../../assets/icons/item_wood01.png";
import wood2 from "../../assets/icons/item_wood02.png";
import wood3 from "../../assets/icons/item_wood03.png";
import wood4 from "../../assets/icons/item_wood04.png";
import areaPera from "../../assets/icons/pera_mark.png";
import itemPera1 from "../../assets/icons/pera_1t.png";
import itemPera2 from "../../assets/icons/pera_2t.png";
import itemPera3 from "../../assets/icons/pera_3t.png";
import itemPera4 from "../../assets/icons/pera_4t.png";
import itemPera5 from "../../assets/icons/pera_5t.png";
import itemPeraOff1 from "../../assets/icons/pera_1t_off.png";
import itemPeraOff2 from "../../assets/icons/pera_2t_off.png";
import itemPeraOff3 from "../../assets/icons/pera_3t_off.png";
import itemPeraOff4 from "../../assets/icons/pera_4t_off.png";
import itemPeraOff5 from "../../assets/icons/pera_5t_off.png";
import areaCalida from "../../assets/icons/calida_mark.png";
import itemCalida1 from "../../assets/icons/calida_1t.png";
import itemCalida2 from "../../assets/icons/calida_2t.png";
import itemCalida3 from "../../assets/icons/calida_3t.png";
import itemCalida4 from "../../assets/icons/calida_4t.png";
import itemCalida5 from "../../assets/icons/calida_5t.png";
import itemCalidaOff1 from "../../assets/icons/calida_1t_off.png";
import itemCalidaOff2 from "../../assets/icons/calida_2t_off.png";
import itemCalidaOff3 from "../../assets/icons/calida_3t_off.png";
import itemCalidaOff4 from "../../assets/icons/calida_4t_off.png";
import itemCalidaOff5 from "../../assets/icons/calida_5t_off.png";
import areaKaru from "../../assets/icons/karu_mark.png";
import itemKaru1 from "../../assets/icons/karu_1t.png";
import itemKaru2 from "../../assets/icons/karu_2t.png";
import itemKaru3 from "../../assets/icons/karu_3t.png";
import itemKaru4 from "../../assets/icons/karu_4t.png";
import itemKaru5 from "../../assets/icons/karu_5t.png";
import itemKaruOff1 from "../../assets/icons/karu_1t_off.png";
import itemKaruOff2 from "../../assets/icons/karu_2t_off.png";
import itemKaruOff3 from "../../assets/icons/karu_3t_off.png";
import itemKaruOff4 from "../../assets/icons/karu_4t_off.png";
import itemKaruOff5 from "../../assets/icons/karu_5t_off.png";
import areaOasis from "../../assets/icons/oasis_mark.png";
import itemOasis1 from "../../assets/icons/oasis_1t.png";
import itemOasis2 from "../../assets/icons/oasis_2t.png";
import itemOasis3 from "../../assets/icons/oasis_3t.png";
import itemOasis4 from "../../assets/icons/oasis_4t.png";
import itemOasis5 from "../../assets/icons/oasis_5t.png";
import itemOasisOff1 from "../../assets/icons/oasis_1t_off.png";
import itemOasisOff2 from "../../assets/icons/oasis_2t_off.png";
import itemOasisOff3 from "../../assets/icons/oasis_3t_off.png";
import itemOasisOff4 from "../../assets/icons/oasis_4t_off.png";
import itemOasisOff5 from "../../assets/icons/oasis_5t_off.png";

export const needList = {
  pera: [
    [
      { icon: mine1, name: "동판", count: 2, max: 100 },
      { icon: magic2, name: "신비한 허브 가루", count: 3, max: 100 },
    ],
    [
      { icon: mine4, name: "미스릴판", count: 2, max: 100 },
      { icon: cook3, name: "보릿가루", count: 3, max: 100 },
    ],
    [
      { icon: potion4, name: "마리오네트 500 포션", count: 3, max: 20 },
      { icon: mine3, name: "금판", count: 5, max: 100 },
    ],
    [
      { icon: magipet3, name: "빤짝이 종이", count: 5, max: 100 },
      { icon: clothes1, name: "최고급 옷감", count: 5, max: 10 },
      { icon: potion1, name: "생명력 500 포션", count: 2, max: 20 },
    ],
    [
      { icon: magic6, name: "뮤턴트", count: 1, max: 30 },
      { icon: magipet4, name: "조화의 코스모스 퍼퓸", count: 2, max: 60 },
      { icon: wood4, name: "특급 나무장작", count: 3, max: 90 },
    ],
  ],
  calida: [
    [
      { icon: potion2, name: "마나 500 포션", count: 1, max: 30 },
      { icon: wood2, name: "고급 나무장작", count: 2, max: 100 },
    ],
    [
      { icon: heulwen3, name: "에너지 컨버터", count: 1, max: 100 },
      { icon: magic4, name: "정화된 토끼의 발", count: 1, max: 50 },
    ],
    [
      { icon: magic5, name: "끈끈이 풀", count: 3, max: 100 },
      { icon: hand4, name: "최고급 바닐라 향초", count: 2, max: 20 },
    ],
    [
      { icon: hand2, name: "인조 잔디", count: 1, max: 50 },
      { icon: heulwen2, name: "에메랄드 퓨즈", count: 1, max: 50 },
      { icon: clothes6, name: "고급 가죽끈", count: 5, max: 100 },
    ],
    [
      { icon: mine5, name: "미스릴 대못", count: 3, max: 50 },
      { icon: hand3, name: "발리스타용 독 묻은 와이번 볼트", count: 3, max: 3 },
      { icon: wood3, name: "최고급 나무장작", count: 3, max: 90 },
    ],
  ],
  karu: [
    [
      { icon: cook1, name: "새우 조련 미끼", count: 4, max: 100 },
      { icon: magic1, name: "실리엔", count: 2, max: 100 },
    ],
    [
      { icon: magipet1, name: "마법의 양피지", count: 1, max: 100 },
      { icon: clothes3, name: "질긴 끈", count: 2, max: 100 },
    ],
    [
      { icon: heulwen1, name: "힐웬 합금", count: 2, max: 100 },
      { icon: cook2, name: "밀가루", count: 5, max: 100 },
    ],
    [
      { icon: heulwen4, name: "스핀 기어", count: 1, max: 20 },
      { icon: wood1, name: "중급 나무장작", count: 5, max: 100 },
      { icon: clothes2, name: "고급 실크", count: 4, max: 10 },
    ],
    [
      { icon: heulwen5, name: "에너지 증폭 장치", count: 2, max: 20 },
      { icon: clothes7, name: "튼튼한 고리", count: 1, max: 30 },
      { icon: magipet2, name: "마법의 깃털펜", count: 5, max: 100 },
    ],
  ],
  oasis: [
    [
      { icon: potion3, name: "스태미나 500 포션", count: 3, max: 20 },
      { icon: clothes5, name: "매듭끈", count: 2, max: 100 },
    ],
    [
      { icon: hand5, name: "쿠션용 솜", count: 1, max: 100 },
      { icon: clothes2, name: "최고급 실크", count: 2, max: 10 },
    ],
    [
      { icon: clothes6, name: "최고급 가죽끈", count: 1, max: 100 },
      { icon: clothes4, name: "질긴 실", count: 3, max: 100 },
    ],
    [
      { icon: potion5, name: "정령의 리큐르", count: 1, max: 5 },
      { icon: mine2, name: "은판", count: 2, max: 100 },
      { icon: clothes1, name: "고급 옷감", count: 4, max: 10 },
    ],
    [
      { icon: magipet5, name: "펫 놀이세트", count: 1, max: 30 },
      { icon: hand1, name: "건초 더미", count: 3, max: 90 },
      { icon: magic3, name: "마력이 깃든 나무장작", count: 5, max: 100 },
    ],
  ],
};

export const itemList = {
  pera: [
    {
      key: "pera1",
      name: "화산머드팩",
      ducat: 4227,
      canBuy: 25,
      slot: 10,
      weight: 15,
      preset: { "성실한 상인": 25, 체리피커: 0, "극한의 한탕": 0 },
    },
    {
      key: "pera2",
      name: "마그마스톤",
      ducat: 8853,
      canBuy: 15,
      slot: 7,
      weight: 15,
      preset: { "성실한 상인": 15, 체리피커: 0, "극한의 한탕": 0 },
    },
    {
      key: "pera3",
      name: "익시온의 뿔",
      ducat: 15733,
      canBuy: 10,
      slot: 7,
      weight: 20,
      preset: { "성실한 상인": 10, 체리피커: 10, "극한의 한탕": 7 },
    },
    {
      key: "pera4",
      name: "화산도마뱀의 알",
      ducat: 36126,
      canBuy: 8,
      slot: 7,
      weight: 25,
      preset: { "성실한 상인": 8, 체리피커: 8, "극한의 한탕": 7 },
    },
    {
      key: "pera5",
      name: "라스파 흑표범 가죽",
      ducat: 148024,
      canBuy: 3,
      slot: 3,
      weight: 30,
      preset: { "성실한 상인": 3, 체리피커: 3, "극한의 한탕": 3 },
    },
  ],
  calida: [
    {
      key: "calida1",
      name: "맥반석 계란",
      ducat: 4376,
      canBuy: 25,
      slot: 10,
      weight: 15,
      preset: { "성실한 상인": 25, 체리피커: 0, "극한의 한탕": 0 },
    },
    {
      key: "calida2",
      name: "칼리다 연어",
      ducat: 7129,
      canBuy: 15,
      slot: 7,
      weight: 15,
      preset: { "성실한 상인": 15, 체리피커: 0, "극한의 한탕": 0 },
    },
    {
      key: "calida3",
      name: "온천 입욕제",
      ducat: 15411,
      canBuy: 10,
      slot: 7,
      weight: 20,
      preset: { "성실한 상인": 10, 체리피커: 10, "극한의 한탕": 0 },
    },
    {
      key: "calida4",
      name: "대형 캠핑 텐트",
      ducat: 32410,
      canBuy: 8,
      slot: 7,
      weight: 25,
      preset: { "성실한 상인": 8, 체리피커: 8, "극한의 한탕": 0 },
    },
    {
      key: "calida5",
      name: "핑크 솔트",
      ducat: 143624,
      canBuy: 3,
      slot: 3,
      weight: 30,
      preset: { "성실한 상인": 3, 체리피커: 3, "극한의 한탕": 3 },
    },
  ],
  karu: [
    {
      key: "karu1",
      name: "우드테이블",
      ducat: 4488,
      canBuy: 25,
      slot: 10,
      weight: 15,
      preset: { "성실한 상인": 25, 체리피커: 0, "극한의 한탕": 0 },
    },
    {
      key: "karu2",
      name: "목공예품",
      ducat: 7242,
      canBuy: 15,
      slot: 7,
      weight: 15,
      preset: { "성실한 상인": 15, 체리피커: 0, "극한의 한탕": 0 },
    },
    {
      key: "karu3",
      name: "스톤 홀스 조각상",
      ducat: 13474,
      canBuy: 10,
      slot: 7,
      weight: 20,
      preset: { "성실한 상인": 10, 체리피커: 10, "극한의 한탕": 7 },
    },
    {
      key: "karu4",
      name: "카루 표고버섯",
      ducat: 28584,
      canBuy: 8,
      slot: 7,
      weight: 25,
      preset: { "성실한 상인": 8, 체리피커: 8, "극한의 한탕": 7 },
    },
    {
      key: "karu5",
      name: "조개 껍질화석",
      ducat: 136460,
      canBuy: 3,
      slot: 3,
      weight: 30,
      preset: { "성실한 상인": 3, 체리피커: 3, "극한의 한탕": 3 },
    },
  ],
  oasis: [
    {
      key: "oasis1",
      name: "고운모래",
      ducat: 3735,
      canBuy: 25,
      slot: 10,
      weight: 15,
      preset: { "성실한 상인": 25, 체리피커: 0, "극한의 한탕": 0 },
    },
    {
      key: "oasis2",
      name: "프리즌고스트 날개",
      ducat: 5897,
      canBuy: 15,
      slot: 7,
      weight: 15,
      preset: { "성실한 상인": 15, 체리피커: 0, "극한의 한탕": 0 },
    },
    {
      key: "oasis3",
      name: "오아시스 그림",
      ducat: 12029,
      canBuy: 10,
      slot: 7,
      weight: 20,
      preset: { "성실한 상인": 10, 체리피커: 10, "극한의 한탕": 7 },
    },
    {
      key: "oasis4",
      name: "선인장 꽃",
      ducat: 28866,
      canBuy: 8,
      slot: 7,
      weight: 25,
      preset: { "성실한 상인": 8, 체리피커: 8, "극한의 한탕": 7 },
    },
    {
      key: "oasis5",
      name: "거대 송곳니 화석",
      ducat: 136913,
      canBuy: 3,
      slot: 3,
      weight: 30,
      preset: { "성실한 상인": 3, 체리피커: 3, "극한의 한탕": 3 },
    },
  ],
};

export const iconByArea = {
  pera: {
    area: areaPera,
    on: [itemPera1, itemPera2, itemPera3, itemPera4, itemPera5],
    off: [itemPeraOff1, itemPeraOff2, itemPeraOff3, itemPeraOff4, itemPeraOff5],
  },
  calida: {
    area: areaCalida,
    on: [itemCalida1, itemCalida2, itemCalida3, itemCalida4, itemCalida5],
    off: [
      itemCalidaOff1,
      itemCalidaOff2,
      itemCalidaOff3,
      itemCalidaOff4,
      itemCalidaOff5,
    ],
  },
  karu: {
    area: areaKaru,
    on: [itemKaru1, itemKaru2, itemKaru3, itemKaru4, itemKaru5],
    off: [itemKaruOff1, itemKaruOff2, itemKaruOff3, itemKaruOff4, itemKaruOff5],
  },
  oasis: {
    area: areaOasis,
    on: [itemOasis1, itemOasis2, itemOasis3, itemOasis4, itemOasis5],
    off: [
      itemOasisOff1,
      itemOasisOff2,
      itemOasisOff3,
      itemOasisOff4,
      itemOasisOff5,
    ],
  },
};
