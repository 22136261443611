import { useState } from "react";
import DeletePresetPopup from "./DeletePresetPopup";
import PresetPopup from "./PresetPopup";

const style = {
  presetPopupBox: "relative top-[-4px] md:top-[-3px]",
  presetButton:
    "text-12 md:text-14 lg:text-16 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-6 md:px-10 lg:px-12 py-2 rounded-md",
};

export default function PresetButton({
  preset,
  onClick,
  isPopup,
  updatePreset,
}) {
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const handleMouseEnter = () => {
    setPopupOpen(true);
  };

  const handleMouseLeave = () => {
    setPopupOpen(false);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    setIsDeletePopupOpen(true);
  };

  const handleClosePopupClick = () => {
    setIsDeletePopupOpen(false);
  };

  return (
    <div className={style.presetPopupBox}>
      <button
        className={style.presetButton}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={handleContextMenu}
      >
        {preset.name}
      </button>
      {popupOpen && isPopup && <PresetPopup preset={preset} />}
      {isDeletePopupOpen && (
        <DeletePresetPopup
          deletePreset={updatePreset}
          targetName={preset.name}
          closePopup={handleClosePopupClick}
        />
      )}
    </div>
  );
}
