const style = {
  popupBox:
    "hidden md:flex z-40 px-16 pt-12 pb-4 absolute text-gray-800 text-10 md:text-12 lg:text-14 flex-col justify-center left-57 w-320 md:w-380 lg:w-450 bg-yellow-50 rounded-16 shadow-md shadow-red-200 border-2 border-solid border-pink-100",
  divLine: "pt-6 border-b-2 border-solid border-pink-200",
  alertMessage: "flex items-center justify-center pt-6 text-center",
};

const resultMessage = ["를 획득합니다!", "와", "를 소모하여"];

export default function AreaItemPopup({ item, userCount }) {
  const buyPrice = parseInt(sessionStorage.getItem(item.key));
  const additionalRate =
    parseInt(sessionStorage.getItem("ranks")) +
    parseInt(sessionStorage.getItem("warrantys"));
  const sellPrice = parseInt(item.ducat * (1 + additionalRate / 100));

  const resultGold = sellPrice - buyPrice;

  let resultMessageKey = false;
  if (resultGold > 0) {
    resultMessageKey = 1;
  } else if (resultGold < 0) {
    resultMessageKey = 2;
  }

  return (
    <div className={style.popupBox}>
      <p>- 주간 교환 횟수: {item.canBuy}</p>
      <p>- 슬롯당 최대수량: {item.slot}</p>
      <p>- 개당 무게: {item.weight}</p>
      <p>- 개당 구매 가격: {buyPrice.toLocaleString()} Gold</p>
      <p>
        - 개당 판매 가격: {sellPrice.toLocaleString()} Gold & Ducat (+
        {additionalRate}% 적용)
      </p>
      <p>
        - 예상 판매 결과:{" "}
        {resultMessageKey &&
          `${Math.abs(resultGold * userCount).toLocaleString()} Gold${
            resultMessage[resultMessageKey]
          } ${(sellPrice * userCount).toLocaleString()} Ducat를 획득합니다!`}
      </p>
      <p></p>

      <div className={style.divLine} />
      <div className={style.alertMessage}>
        <p>
          <span className="text-red-600">[주의]</span> 판매 가격은 실시간 가격이
          아닙니다! <br />* 판매 가격은 울라 대륙 교역소의{" "}
          <span className="text-blue-600">평균가격</span>입니다.
        </p>
      </div>
    </div>
  );
}
