const style = {
  addPresetPopupBackground:
    "fixed flex items-center top-0 left-0 bg-black bg-opacity-70 w-full h-full z-50",
  container:
    "flex flex-col bg-white w-360 lg:w-480 h-120 lg:h-160 rounded-xl m-auto p-24 lg:p-36 justify-between",
  warningText: "text-16 lg:text-18",
  buttonBox: "flex gap-8",
  buttonPrimary:
    "bg-red-300 hover:bg-red-400 active:bg-red-500 grow px-12 py-4 lg:py-6 rounded-md",
  buttonSecondary:
    "bg-gray-300 hover:bg-gray-400 active:bg-gray-500 grow px-12 py-4 lg:py-6 rounded-md",
};

export default function DeletePresetPopup({
  deletePreset,
  targetName,
  closePopup,
}) {
  const handleDeletePresetClick = () => {
    const currentPresetList = JSON.parse(localStorage.getItem("preset"));
    const deletedPresetList = currentPresetList.filter(
      (preset) => preset.name !== targetName
    );

    localStorage.setItem("preset", JSON.stringify(deletedPresetList));

    deletePreset();
    closePopup();
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  return (
    <div
      className={style.addPresetPopupBackground}
      onClick={handleBackgroundClick}
    >
      <div className={style.container}>
        <p className={style.warningText}>[{targetName}] 프리셋을 삭제할까요?</p>
        <div className={style.buttonBox}>
          <button
            className={style.buttonPrimary}
            onClick={handleDeletePresetClick}
          >
            삭제
          </button>
          <button className={style.buttonSecondary} onClick={closePopup}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
}
